import payload_plugin_3tgQBkZxjs from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.26.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_hiZPbHUB25 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RUJ3hsj9tN from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NPh4BHerYo from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TXpfdzfaFY from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hOVU1sNM5n from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0pPeP69Gwc from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZgukVfGAu2 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_039yQ8lCVY from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.26.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/bgrand/Documents/Projects/live-display/player/.nuxt/components.plugin.mjs";
import prefetch_client_pPxbTM6kmu from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import airbrake_H5MbEqRsNX from "/home/bgrand/Documents/Projects/live-display/shared/plugins/airbrake.ts";
import firebase_MrRCoKgj2U from "/home/bgrand/Documents/Projects/live-display/shared/plugins/firebase.ts";
import luxon_VlqPBC7NBo from "/home/bgrand/Documents/Projects/live-display/shared/plugins/luxon.ts";
import route_xWBYLf55o4 from "/home/bgrand/Documents/Projects/live-display/shared/plugins/route.ts";
export default [
  payload_plugin_3tgQBkZxjs,
  revive_payload_client_hiZPbHUB25,
  unhead_RUJ3hsj9tN,
  router_NPh4BHerYo,
  payload_client_TXpfdzfaFY,
  navigation_repaint_client_hOVU1sNM5n,
  check_outdated_build_client_0pPeP69Gwc,
  chunk_reload_client_ZgukVfGAu2,
  plugin_vue3_039yQ8lCVY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pPxbTM6kmu,
  airbrake_H5MbEqRsNX,
  firebase_MrRCoKgj2U,
  luxon_VlqPBC7NBo,
  route_xWBYLf55o4
]